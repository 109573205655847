import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import validUrl from 'valid-url'
import qs from 'query-string'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import NotificationAlert from 'react-notification-alert'
import { SEO } from '../components/seo/Seo'
import { QueryParamsList } from '../components/queryParamsList/QueryParamsList'
import { CookiesList } from '../components/cookiesList/CookiesList'
import { StepTop } from '../components/stepTop/StepTop'
import { RequestForm } from '../components/requestForm/RequestForm'
import { Header } from '../components/header/Header'
import { Instructions } from '../components/instructions/Instructions'
import 'react-notification-alert/dist/animate.css'

const indexPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState('')
  const [steps, setSteps] = useState([])
  const notifyRef = useRef()
  const notify = (type, message) => {
    return notifyRef.current.notificationAlert({
      place: 'br',
      message,
      type,
      autoDismiss: 5,
    })
  }

  const handleGo = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (!validUrl.isUri(url)) {
      notify('warning', 'URL is not valid!')
      return setIsLoading(false)
    }
    const { data } = await newProcess()
    setSteps(data)
    console.log({ data })
    return setIsLoading(false)
  }
  const newProcess = async () => {
    const { data } = await axios({
      method: 'post',
      url: '/.netlify/functions/track-new', // http://localhost:9000
      data: { url },
    })
    return data
  }
  return (
    <>
      <SEO
        title='Redirects.show'
        keywords={[
          'redirects.show',
          'redirect',
          'redirects',
          'trace',
          'tracker',
          'developer',
          'devops',
          'nodejs',
          'react',
        ]}
      />
      <Header />
      <Container>
        <NotificationAlert ref={notifyRef} />
        <Row style={{ margin: '15px 0' }}>
          <Col md={{ span: 6, offset: 3 }}>
            <RequestForm
              isLoading={isLoading}
              submitForm={handleGo}
              handleChangeUrl={setUrl}
              url={url}
            />
            {steps.length === 0 && <Instructions />}

            <Accordion>
              {steps.map((s, i) =>
                s.url ? (
                  <Card key={i}>
                    <StepTop
                      hasNext={!!steps[i + 1]}
                      url={s.url}
                      responseStatus={s.code}
                      hasQueryParams={s.queryParams && s.queryParams.length > 0}
                      hasCookiesData={s.cookies && s.cookies.length > 0}
                      accordionId={i}
                    />
                    {s.queryParams && s.queryParams.length > 0 && (
                      <QueryParamsList
                        queryParamsData={s.queryParams}
                        accordionId={i}
                      />
                    )}
                    {s.cookies && s.cookies.length > 0 && (
                      <CookiesList cookiesData={s.cookies} accordionId={i} />
                    )}
                  </Card>
                ) : null
              )}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default indexPage
